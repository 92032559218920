// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuI8znTh-I2Cc8NDUb9Z7I8NIbFFVbDq0",
  authDomain: "she-interiors.firebaseapp.com",
  projectId: "she-interiors",
  storageBucket: "she-interiors.appspot.com",
  messagingSenderId: "1002394859166",
  appId: "1:1002394859166:web:c603314a64a66a7910ad8f"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);